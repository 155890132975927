<template>
	<div class="page container">
		<v2-back-btn />
		<div class="page__info">
			<div class="page__info--title">
				Edit deal information
			</div>
		</div>

		<div class="content">
			<div class="main">
				<validation-observer
					data-element="form"
					tag="form"
					ref="form"
					v-if="formLoaded"
					@submit.prevent="submit"
				>
					<input-field
						name="Name"
						type="text"
						rules="required|max:30"
						v-model="deal.name"
					/>
					<checkbox-field
						name="Classified"
						id="classified"
						v-model="deal.classified"
					/>
					<range-field
						name="Experience level"
						:optionLabel="['value', 'text']"
						:options="experienceLevels"
						rules=""
						:lazy="true"
						:showRail="false"
						:labelMarks="experienceLevelMarks"
						v-model="deal.experienceLevel"
					/>
					<select-field
						name="Bidding systems"
						:options="biddingSystemTypeOptions"
						:emptyAllowed="false"
						v-model="biddingSystemType"
					/>
					<select-field
						v-if="getShowSpecificBiddingSystems"
						name="Specific bidding systems"
						:options="biddingSystemOptions"
						v-model="deal.biddingSystems"
						multiple
					/>
					<text-note
						name="Dealer"
						:value="deal.dealer"
					/>
					<focus-keywords
						:focusVal="deal.focus"
						:keywordsVal="deal.keywords"
						:formLoaded="formLoaded"
						entryRoute="teaching"
						@onKeyword="updateKeywords"
						@onFocus="updateFocus"
					/>
					<select-field
						name="Tags"
						:tags="true"
						:multiple="true"
						:options="tagOptions"
						v-model="deal.tags"
					/>
					<checkbox-field
						name="DDS should prioritize the lowest cards"
						id="dds-prioritizes-lowest-cards"
						v-model="deal.ddsPrioritizesLowestCards"
					/>
				</validation-observer>
			</div>

			<div class="side">

				<div class="deal__side--info">
					<div class="deal__side--info-title">
						<icon icon="v2-deal-settings" colour="transparent" />
						Edit
					</div>

					<div class="deal__side--info-btns">
						<actions
							:actions="getActions"
							@actionClick="onActionClick"
						/>
						<div class="separator" />
						<deal-parameters :deal="deal" />
						<div class="separator" />
						<actions
							:actions="getSecondActions"
							@actionClick="onActionClick"
						/>
					</div>

					<div class="deal__side--info" v-if="getGameState">
						<div class="deal__side--info-title">
							<icon icon="v2-gameplay" colour="transparent" />
							Gameplay
						</div>

						<div class="deal__side--info-summary">
							<deal-summary
								v-if="getGameState"
								:deal="deal"
							/>
						</div>
					</div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>

	import dayjs from 'dayjs';
	import SelectField from '@/components/forms/SelectField';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import Actions from '@/components/ui/Actions';
	import InputField from '@/components/forms/InputField';
	import CheckboxField from '@/components/forms/CheckboxField';
	import FocusKeywords from '@/components/forms/FocusKeywords';
	import RangeField from '@/components/forms/RangeField';
	import TextNote from '@/components/forms/TextNote';
	import DealSummary from '@/components/diagrams/DealSummary';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import api from '@/services/api';
	import { seatPositions } from '@/consts';
	import { experienceLevels } from '@/consts';
	import { biddingSystems } from '@/consts';
	import { vulnerabilities } from '@/consts';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import DealParameters from '@/components/dealLibrary/DealParameters';
	import Icon from '@/components/ui/Icon';

	dayjs.extend(relativeTime);

	export default {

		layout: 'v2default',

		components: {
			InputField,
			FocusKeywords,
			SelectField,
			CheckboxField,
			RangeField,
			DealSummary,
			TextNote,
			Actions,
			V2BackBtn,
			DealParameters,
			Icon
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			formLoaded: false,
			deal: {
				createdDate:      '',
				name:             '',
				classified:       undefined,
				dealer:           '',
				filename:         '',
				focus:            '',
				keywords:         '',
				tags:             [],
				biddingSystems:   undefined,
				experienceLevel:  1
			},
			tags: [],
			tricksOptions: [...Array(14).keys()].map((trick) => {
				return {
					text: trick,
					value: trick
				};
			}),
			experienceLevelMarks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			experienceLevels: experienceLevels,
			vulnerabilitiesOptions: vulnerabilities.map((vul) => {
				return {
					text: vul,
					value: vul
				};
			}),
			dealerPositionOptions: seatPositions,
			biddingSystemType: 'specific',
			biddingSystemTypeOptions: [
				{
					text: 'Specific bidding systems',
					value: 'specific'
				},
				{
					text: 'Any bidding system',
					value: 'any'
				}
			],
			biddingSystemOptions: biddingSystems.map((biddingSystem) => {
				return {
					text: biddingSystem.value,
					value: biddingSystem.value
				};
			}),
			previousDealId: null,
			nextDealId: null
		}),
		computed: {
			getCrumbRoute () {
				return '/teaching/deals';
			},
			getDealRoute () {
				return `${this.getCrumbRoute}/${this.getDealId}`;
			},
			tagOptions () {
				if (!this.tags) {
					return false;
				}
				return this.tags.map((tag) => {
					return {
						text: tag,
						value: tag
					};
				});
			},
			getDealId () {
				if (!this.deal?.id) {
					return false;
				}
				return this.deal.id;
			},
			getDealIsFirstDealInQuery () {
				return this.$store.getters['dealLibrary/getDealIsFirstDealInQueryById']({
					id: this.getDealId
				});
			},
			getDealIsLastDealInQuery () {
				return this.$store.getters['dealLibrary/getDealIsLastDealInQueryById']({
					id: this.getDealId
				});
			},
			getShowSpecificBiddingSystems () {
				return Array.isArray(this.deal.biddingSystems);
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							actionId: 'saveAndReturnToDealPage',
							variant: 'red'
						},
						{
							text: 'Cancel',
							route: this.getDealRoute,
							variant: 'outline'
						}
					]
				};
			},
			getSecondActions () {
				return {
					primary: [
						(
							this.previousDealId ? {
								text: 'Save and edit previous',
								actionId: 'saveAndNavigateToPreviousDeal'
							} : false
						),
						(
							this.previousDealId ? {
								text: 'Previous deal',
								actionId: 'navigateToPreviousDeal'
							} : false
						),
						(
							this.nextDealId ? {
								text: 'Next deal',
								actionId: 'navigateToNextDeal'
							} : false
						),
						(
							this.nextDealId ? {
								text: 'Save and edit next deal',
								actionId: 'saveAndNavigateToNextDeal'
							} : false
						)
					].filter(Boolean)
				};
			},
			getGameState () {
				if (!this.deal) {
					return false;
				}
				return this.deal.deal;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/deals',
						text: 'Deal library'
					},
					{
						path: `/teaching/deals/${this.getDealId}`,
						text: this.deal.name
					},
					{
						path: `/teaching/deals/${this.getDealId}/edit`,
						text: 'Edit'
					}
				];
			},
			getDealContext () {
				return this.$route?.query.context || 'library';
			},
			getDealLessonId () {
				return this.$route.query?.lessonId;
			},
			getDealQuery () {
				const query = {
					context: this.getDealContext
				};

				if (this.getDealLessonId) {
					query.lessonId = this.getDealLessonId;
				}

				return query;
			}
		},
		watch: {
			'deal.biddingSystems' (newVal) {
				if (Array.isArray(newVal)) {
					this.biddingSystemType = 'specific';
					return;
				}
				this.biddingSystemType = 'any';
			},
			biddingSystemType (newVal) {
				if (newVal === 'specific') {
					if (Array.isArray(this.deal.biddingSystems)) {
						return false;
					}
					this.deal.biddingSystems = [];
					return;
				}
				if (this.deal.biddingSystems === null) {
					return false;
				}
				this.deal.biddingSystems = null;
			}
		},
		mounted () {
			this.loadContent();
		},
		methods: {
			navigateToPreviousDeal () {
				this.$router.push({
					path: `/teaching/deals/${this.previousDealId}/edit`,
					query: this.getDealQuery
				});
			},
			navigateToNextDeal () {
				this.$router.push({
					path: `/teaching/deals/${this.nextDealId}/edit`,
					query: this.getDealQuery
				});
			},
			async saveAndNavigateToPreviousDeal () {
				await this.updateDeal();
				this.navigateToPreviousDeal();
				this.$store.commit('ui/showNotification', {
					notification: 'The deal has been updated'
				});
			},
			async saveAndNavigateToNextDeal () {
				await this.updateDeal();
				this.navigateToNextDeal();
				this.$store.commit('ui/showNotification', {
					notification: 'The deal has been updated'
				});
			},
			async saveAndReturnToDealsList () {
				await this.updateDeal();
				await this.$router.push(this.getCrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The deal has been updated'
				});
			},
			async saveAndReturnToDealPage () {
				await this.updateDeal();
				await this.$router.push(this.getDealRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The deal has been updated'
				});
			},
			updateFocus (val) {
				this.deal.focus = val;
			},
			updateKeywords (val) {
				this.deal.keywords = val;
			},
			async loadContent () {
				try {
					await Promise.all([
						this.setTags(),
						this.setDeal(),
						this.fetchPreviousAndNextDeals()
					]);
				} catch {
					this.$store.commit('ui/showError');
					return;
				}
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async setTags () {
				const tags = await api.deals.getTags();
				if (!tags) {
					return;
				}
				this.tags = tags;
			},
			async updateDeal () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const deal = await api.deals.updateDeal({
					dealId: this.$route.params.dealId,
					deal: this.deal
				});
				if (!deal) {
					return false;
				}
			},
			async setDeal () {
				const deal = await api.deals.getDeal({
					dealId: this.$route.params.dealId
				});
				if (!deal) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.deal = deal;
				if (!this.deal.experienceLevel) {
					this.deal.experienceLevel = 1;
				}
			},
			async fetchPreviousAndNextDeals () {
				const { prev, next } = await api.deals.getPreviousAndNextDeals({
					dealId: this.$route.params.dealId,
					context: this.getDealContext,
					lessonId: this.getDealLessonId
				});

				if (prev) {
					this.previousDealId = prev;
				}

				if (next) {
					this.nextDealId = next;
				}
			}
		}
	};

</script>

<style lang="scss" scoped>
.content {

  .main {
    width: calc(100% - 30px - 425px)
  }

  .side {
    width: 425px;
  }

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;

    .main,
    .side {
      width: 100%;
    }
  }
}

.deal {

  &__side {

    &--info {
      display: flex;
      flex-direction: column;
      background: #fafaf9;

      &-title {
        display: flex;
        align-items: center;
        padding: 7.5px 0;
        margin-bottom: 12px;
        gap: 10px;
        @include font(18px, $c-darkest-navy, 700, 23px);
      }

      &-summary {

        ::v-deep [data-component="hand"] {
          border: none;
        }

        ::v-deep [data-component="deal-matrix"] {
          z-index: 10000;
          background: $c-white-l;
          border-radius: 4px;
        }

        ::v-deep [data-component="bidding-table"] {
          z-index: 10000;
          background: $c-white-l;
          border-radius: 4px;
          width: 100%;

          [data-element="table"] {
            width: 100%;
          }
        }
      }

      &-btns {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 36px;

        ::v-deep [data-component="actions"] {
          display: flex;
          flex-direction: column;
          padding: 0;

          [data-component="btn"] {
            width: 100%;
            margin-bottom: 0 !important;
          }

          [data-element="actions-primary"] {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin: 0;
          }
        }

        .separator {
          margin: 6px 0;
        }
      }
    }
  }
}
</style>
